@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.chat_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: whitesmoke;
    font-family: 'Roboto', sans-serif;
}

body {
    padding: 0;
    margin: 0;
}

section.chat__section {
    align-items: center;
    width: 800px;
    max-width: 90%;
    background: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.brand {
    padding: 20px;
    background: #f1f1f1;
    display: flex;
    align-items: center;
}

.brand h1 {
    text-transform: uppercase;
    font-size: 20px;
    color: #444;
    margin-left: 10px;
}

.message__area {
    height: 500px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 40px;
}

textarea {
    width: 100%;
    border: none;
    padding: 20px;
    font-size: 16px;
    outline: none;
    background: #FBFBFB;
}

.incoming {
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 40px;
    max-width: 300px;
    position: relative;
    overflow-wrap: break-word;

    background: #8F8BE8;
    color: #fff;
}

.outgoing {
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 40px;
    max-width: 300px;
    position: relative;
    overflow-wrap: break-word;

    background: #e9eafd;
    color: #787986;
    margin-left: auto;
}

.message h4 {
    position: absolute;
    top: -20px;
    left: 0;
    color: #333;
    font-size: 14px;
}

.ldBtn {
    margin-top: 10px;
    background: #f1f1f1;
    display: flex;
    align-items: center;
}