.log_in {
    border: 1px solid black;
    border-radius: 20px;
    text-align: center;
    width: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: whitesmoke;
    font-family: 'Roboto', sans-serif;
    margin: auto;
    box-shadow: 5px 5px #888888;
    margin-top: 50px;
}

button {
    width: 180px;
    height: 23px;
    border-radius: 10px;
    color: #8F8BE8;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 5px 5px #888888;
}

input {
    border-radius: 5px;
    width: 180px;
    height: 30px;
}

h1 {
    color: #8F8BE8;
}